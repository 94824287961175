import logger from 'OK/logger';

var OPERATION_NAME = "ajaxrequestdatatime.send.time";
var OPERATION_CLIENT_MEASURE_NAME = "ajaxrequestdatatime.client.measure";
var OPERATION_SERVER_MEASURE_NAME = "ajaxrequestdatatime.server.measure";
var StateIds;
var RegionId;
var CountryCode;
var activated;
var isInit = false;

/**
 * @param {String[]} stringArray - список stateId из pms
 * @param {String} region - id региона пользователя
 * @param {String} countryCode - код страны
 */
function init(stringArray, region, countryCode) {
    RegionId = region;
    CountryCode = countryCode;
    StateIds = JSON.parse(stringArray);
    isInit = true;
}

/**
 * @param val {number}
 * @returns {number}
 */
function round(val) {
    var
        /** @type {number} */
        delimiter = 100,
        /** @type {number} */
        max = 2000,
        /** @type {number} */
        base;
    if (val < delimiter) {
        /* "Мгновенные" клиенты */
        return 1;
    }
    if (val > max) {
        /* Потолок */
        val = max;
    }
    base = ~~(val / delimiter);
    return delimiter * base;
}

/**
 * @param {number} requestTime - время на пересылку данных по сети
 * @param {String} url - ссылка куда переходит пользователь
 * @param {?String} renderMeasureTimeHeader - идентификатор операции и серверное время
 */
function log(requestTime, url, renderMeasureTimeHeader) {
    if (isInit) {
        var stateId = findStateIdInUrl(url);

        if (stateId.length > 0) {
            logger.duration(OPERATION_NAME, requestTime, CountryCode + "_" + stateId, RegionId);
            var d = round(requestTime);
            if (d) {
                logger.success('ad', 'ajax_' + CountryCode + '_' + stateId, d);
            }
        }
    }
    if (renderMeasureTimeHeader) {
        var splitted = renderMeasureTimeHeader.split(':');
        var key = splitted[0];
        var responseTime = (Number(splitted[1])) / 1000000; // Серверное время в наносекундах, нормируем к миллисекундам
        var requestRange = getRange(requestTime);
        var responseRange = getRange(responseTime);
        if (requestRange && responseRange) {
            logger.success(OPERATION_CLIENT_MEASURE_NAME, key, requestRange);
            logger.success(OPERATION_SERVER_MEASURE_NAME, key, responseRange);
        }
    }
}

/**
 *
 * @param duration
 * @returns {string|null}
 */
function getRange(duration) {
    if (duration < 0) {
        return null;
    }
    var range = [0, 20, 40, 60, 100, 160, 260, 320, 580, 900];
    for (var i = 0; i < range.length - 1; i++) {
        var lowValue = range[i];
        var highValue = range[i + 1];
        if ((duration > lowValue) && (duration <= highValue)) {
            return lowValue + '-' + highValue;
        }
    }
    return range[range.length - 1] + '+';
}

function findStateIdInUrl(url) {
    var
        /** @type {?string} */
        userStateId = findUriParameterValue("st.cmd", url),
        /** @type {?string} */
        userStateCmd = findUriParameterValue("cmd", url);
    for (var i = 0; i < StateIds.length; i++) {
        var curr = StateIds[i];

        if (curr.indexOf("_") > -1) {
            var split = curr.split("_");

            var stCmd = split[0];
            var cmd = split[1];
            if ('*' === stCmd && userStateCmd === cmd) {
                return cmd;
            }
            if (userStateId === stCmd && userStateCmd === cmd) {
                return userStateId + "_" + userStateCmd;
            }
        } else {
            if (userStateId === curr) {
                return curr;
            }
        }
    }
    return "";
}

function findUriParameterValue(parameter, url) {
    var params = url.substring(Math.max(0, url.indexOf('?') + 1)).split('&');

    for (var i = 0; i < params.length; i++) {
        var param = params[i];
        var indexOf = param.indexOf(parameter + '=');
        if (indexOf === 0) {
            return param.substr(parameter.length + 1);
        }
    }
    return "";
}

function activate(element) {
    if (activated) {
        return;
    }
    init(element.textContent, element.getAttribute("data-region"), element.getAttribute("data-countrycode"));
    activated = true;
}

function deactivate() {
    StateIds.length = 0;
    RegionId = "";
    CountryCode = "";
    activated = false;
}

export default { log, activate, deactivate };

export { log, activate, deactivate };
